'use es6';

// this is hardcoded in i18n
const CACHE_KEY = 'i18n-cached-standard-locales';
let loaded = false;
const loadLocales = () => {
  performance.mark('mark-i18n-early-load-start');
  import('i2l?mode=very-lazy&query=sporks!../../lang/en.lyaml').then(({
    default: enLang
  }) => {
    performance.mark('mark-i18n-early-load-end');
    try {
      if (localStorage) {
        const locales = JSON.parse(localStorage.getItem(CACHE_KEY));
        locales.forEach(locale => {
          if (Object.prototype.hasOwnProperty.call(enLang.context, locale)) {
            loaded = true;
            enLang.context[locale]();
          }
        });
      }
    } catch (err) {
      // ignore
    }
    if (!loaded) {
      enLang.context.en();
    }
  }).catch(err => {
    console.error('Failed to load locales:', err);
  });
};
loadLocales();